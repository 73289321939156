import { Controller } from "@hotwired/stimulus"

export default class ReplacementVehicule extends Controller {
  static targets = ["insurance", "car", "cars", "replacedBy", "replacedById"]
  static values = { deleteUrl: String, csrf: String }

  connect() {
    this.insurance = ""
  }

  updateInsurance() {
    this.insurance = this.insuranceTarget.value
  }

  selectCar(event) {
    const carElement = event.target.closest("[data-replacement-vehicule-target=\"car\"]")
    this.replacedByTarget.value = carElement.dataset.replacementVehiculeCarNameValue
    this.replacedByIdTarget.value = carElement.dataset.replacementVehiculeCarIdValue
    this.close()
  }

  remove() {
    const replacedById = this.replacedByIdTarget.value
    this.replacedByTarget.value = ""
    this.replacedByIdTarget.value = ""

    if (!this.deleteUrlValue || !replacedById) {
      return
    }

    // eslint-disable-next-line compat/compat
    fetch(this.deleteUrlValue + `?replacement_car_pk=${replacedById}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": this.csrfValue,
      },
    })
  }

  close() {
    this.carsTarget.classList.add("-hidden")
  }
}
