import { Controller } from "@hotwired/stimulus"

const LIGHT_MODE = "ctx-light-mode"
const DARK_MODE = "ctx-dark-mode"

export default class Theme extends Controller {
  static values = { csrf: String, url: String }

  set() {
    const currentTheme = this.element.classList.contains(DARK_MODE) ? DARK_MODE : LIGHT_MODE
    const newTheme = currentTheme === DARK_MODE ? LIGHT_MODE : DARK_MODE
    this.element.classList.remove(currentTheme)
    this.element.classList.add(newTheme)

    document.dispatchEvent(new Event("theme-change"))

    // eslint-disable-next-line compat/compat
    fetch(this.urlValue, {
      method: "POST",
      headers: {
        "X-CSRFToken": this.csrfValue,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ theme: newTheme === DARK_MODE ? "dark" : "light" }),
    })
  }
}
