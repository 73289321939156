import { Controller } from "@hotwired/stimulus"

const HIDDEN_CLASS = "-hidden"

export default class InsuranceCreator extends Controller {
  static targets = ["insuranceName", "insuranceNotUnique", "insuranceNotTheSame", "submit"]
  static values = { csrf: String, createUrl: String, additionalId: String }

  checkInsuranceName(event) {
    // eslint-disable-next-line compat/compat
    fetch(
        `/api/insurance/check-unicity/?name=${event.currentTarget.value}`
        , { method: "GET" },
    ).then(response => response.json()).then(data => {
      if (data.unicity) {
        this.insuranceNotUniqueTarget.classList.add(HIDDEN_CLASS)
        this.submitTarget.disabled = false
      } else {
        this.insuranceNotUniqueTarget.classList.remove(HIDDEN_CLASS)
        this.submitTarget.disabled = true
      }
    })
  }

  checkInsuranceNameConfirmation(event) {
    if (event.currentTarget.value === this.insuranceNameTarget.value) {
      this.insuranceNotTheSameTarget.classList.add(HIDDEN_CLASS)
      this.submitTarget.disabled = false
    } else {
      this.insuranceNotTheSameTarget.classList.remove(HIDDEN_CLASS)
      this.submitTarget.disabled = true
    }
  }

  submit() {
    if (!this.submitTarget.disabled) {
      fetch(
        this.createUrlValue,
        {
          method: "POST",
          body: JSON.stringify({ name: this.insuranceNameTarget.value }),
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": this.csrfValue,
          },
        },
      ).then(response => response.json()).then(data => {
        document.getElementById(this.additionalIdValue).classList.add(HIDDEN_CLASS)
        const newInsuranceId = data.id
        fetch(`${this.createUrlValue}${newInsuranceId}/render-box/`, { method: "GET" })
          .then(response => response.json()).then(data => {
            const insurancesBox = document.getElementById("insurances-box")
            const children = new DOMParser().parseFromString(data.html, "text/html").body.children
            Array.from(children).forEach(child => insurancesBox.appendChild(child))
          })
      })
    }
  }
}
