import { Controller } from "@hotwired/stimulus"

const HIDDEN_CLASS = "-hidden"

export default class Filter extends Controller {
  static targets = ["stateTag", "stateChoices", "stateChoice"]

  closeStateChoices() {
    this.stateChoicesTarget.classList.add(HIDDEN_CLASS)
  }

  openStateChoices() {
    this.stateChoicesTarget.classList.remove(HIDDEN_CLASS)
  }
}
