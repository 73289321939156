import { Application } from "@hotwired/stimulus"
import "htmx.org"

// cars
import ReplacementVehicule from "../../../cars/static_src/cars/components/ReplacementVehicule"
import Filter from "../../../cars/static_src/cars/components/Filter"
import Form from "../../../cars/static_src/cars/components/Form"
import InsuranceCreator from "../../../cars/static_src/cars/components/InsuranceCreator"
import InsuranceSelecter from "../../../cars/static_src/cars/components/InsuranceSelecter"

// notifications
import Notification from "../../../notifications/static_src/notifications/components/Notification"

// ui
import Theme from "./components/Theme"
import WorkChartHome from "./components/WorkChartHome"

const app = Application.start()
app.register("filter", Filter)
app.register("replacement-vehicule", ReplacementVehicule)
app.register("form", Form)
app.register("insurance-creator", InsuranceCreator)
app.register("insurance-selecter", InsuranceSelecter)
app.register("theme", Theme)
app.register("work-chart-home", WorkChartHome)
app.register("notification", Notification)
