import { Controller } from "@hotwired/stimulus"

const HIDDEN_CLASS = "-hidden"

export default class InsuranceSelecter extends Controller {
  static targets = ["insurances", "insurance", "insuranceId", "insuranceName"]

  close() {
    this.insurancesTarget.classList.add("-hidden")
  }

  select(event) {
    const insuranceElement = event.target.closest("[data-insurance-selecter-target=\"insurance\"]")
    const checkBox = document.getElementById(`insurance-${insuranceElement.dataset.insuranceSelecterIdValue}`)
    checkBox.checked = true
    const box = document.getElementById(`insurance-box-${insuranceElement.dataset.insuranceSelecterIdValue}`)
    box.classList.remove(HIDDEN_CLASS)
    this.insuranceNameTarget.value = ""
    this.close()
  }

  remove(event) {
    const insuranceElement = event.target.closest("[data-insurance-selecter-target=\"insurance-selection\"]")
    const insuranceId = insuranceElement.dataset.insuranceSelecterIdValue
    const checkbox = document.getElementById(`insurance-${insuranceId}`)
    checkbox.checked = false
    insuranceElement.parentNode.classList.add(HIDDEN_CLASS)
  }
}
