import { Controller } from "@hotwired/stimulus"

export default class Notification extends Controller {
  static values = { url: String, csrf: String }

  markViewed(event) {
    // eslint-disable-next-line compat/compat
    fetch(this.urlValue, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": this.csrfValue,
      },
    }).then(() => {
      document.getElementById("notification-not-updated-cars").remove()
    })
    event.preventDefault()
    event.stopPropagation()
  }
}
