import { Controller } from "@hotwired/stimulus"

const HIDDEN_CLASS = "-hidden"

export default class Form extends Controller {
  static targets = [
    "error",
    "submit",
    "startDate",
    "workHours",
    "breakageDate",
    "expertiseDate",
    "arrivalDate",
    "dueDate",
    "repairing",
    "invoiceReady",
    "replacementCar",
    "replacementReturned",
    "wreckDelivered",
  ]

  static values = {
    additionalId: String,
  }

  displayInputs(event) {
    const state = event.currentTarget.value
    this.resetForm()

    if (state === "coming" || state === "todo") {
      this.startDateTarget.classList.remove(HIDDEN_CLASS)
      this.workHoursTarget.classList.remove(HIDDEN_CLASS)
      this.dueDateTarget.classList.remove(HIDDEN_CLASS)
    }

    if (state === "waiting") {
      this.workHoursTarget.classList.remove(HIDDEN_CLASS)
      this.arrivalDateTarget.classList.remove(HIDDEN_CLASS)
    }

    if (state === "breakage") {
      this.breakageDateTarget.classList.remove(HIDDEN_CLASS)
      this.wreckDeliveredTarget.classList.remove(HIDDEN_CLASS)
    }

    if (state === "expertise") {
      this.expertiseDateTarget.classList.remove(HIDDEN_CLASS)
    }

    if (state === "repairing") {
      this.repairingTarget.classList.remove(HIDDEN_CLASS)
      this.workHoursTarget.classList.remove(HIDDEN_CLASS)
    }

    if (state === "reassembly") {
      this.workHoursTarget.classList.remove(HIDDEN_CLASS)
    }

    if (state === "finished") {
      this.invoiceReadyTarget.classList.remove(HIDDEN_CLASS)
      this.replacementReturnedTarget.classList.remove(HIDDEN_CLASS)
    }
  }

  onReplacementCarNeed(event) {
    this.replacementCarTarget.classList.toggle(HIDDEN_CLASS, !event.currentTarget.checked)
  }

  openInsuranceModal() {
    document.getElementById(this.additionalIdValue).classList.remove(HIDDEN_CLASS)
  }

  // #### Internal methods ####

  markAsInvalid() {
    this.errorTarget.classList.remove(HIDDEN_CLASS)
    this.submitTarget.disabled = true
  }

  markAsValid() {
    this.errorTarget.classList.add(HIDDEN_CLASS)
    this.submitTarget.disabled = false
  }

  resetForm() {
    this.startDateTarget.classList.add(HIDDEN_CLASS)
    this.workHoursTarget.classList.add(HIDDEN_CLASS)
    this.breakageDateTarget.classList.add(HIDDEN_CLASS)
    this.expertiseDateTarget.classList.add(HIDDEN_CLASS)
    this.arrivalDateTarget.classList.add(HIDDEN_CLASS)
    this.dueDateTarget.classList.add(HIDDEN_CLASS)
    this.repairingTarget.classList.add(HIDDEN_CLASS)
    this.invoiceReadyTarget.classList.add(HIDDEN_CLASS)
    this.replacementReturnedTarget.classList.add(HIDDEN_CLASS)
    this.wreckDeliveredTarget.classList.add(HIDDEN_CLASS)
  }
}
